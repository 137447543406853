<template>
    <div class="min-h-screen w-full text-black pt-4">
        <div id="render-element"></div>
        <h1 class="h2 display-text mb-3 mt-24 px-4 text-center">{{ strings.title }}</h1>
        <p class="px-4 text-center">{{ strings.description }}</p>
        <div class="w-full h-full mt-10 pb-4" style="background-color: #f9f8f5">
            <iframe id="wl-findhelp" class="w-full findhelp-home" title="Wellness League Search" src="https://thewellnessleague.findhelp.com/">
            </iframe>
        </div>
    </div>
</template>

<script>
import text from "./findHelpText";

export default {
    name: "FindHelp",
    data() {
        return {
            strings: text,
        }
    }
};
</script>

<style scoped>
/* Try to have at least the findhelp homepage fit entirely without scroll on each screen size */
    .findhelp-home {
        min-height: 1020px;
    }

    @media (max-width: 400px) {
        .findhelp-home {
            min-height: 1100px;
        }
    }

    @media (min-width: 992px) {
        .findhelp-home {
            min-height: 920px;
        }
    }
    
</style>